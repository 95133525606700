export function initInScope($scope) {
    var $navbarBrand = $scope.find('.js-navbar-brand');
    var $navbarWrapper = $scope.find('.js-navbar-wrapper');

    //add padding-bottom to navbar-wrapper if the page has no hero
    if (!$('.hero__image').length && !$('.hero-headline-badge__image').length && !$('.hero__video').length && !$('#pagestrip').length) {
        $navbarWrapper.addClass('navbar--padding-bottom');
    }

    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 100) {
            $navbarBrand.addClass('is-scrolled');
        } else {
            $navbarBrand.removeClass('is-scrolled');
        }
    });
}